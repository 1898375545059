@import '../../styles/variables';

.checkbox-wrapper label {
  display: flex;
  align-items: center;
  width: 100%;

  input[type='checkbox'] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    outline: none;

    /* creating a custom design */
    width: var(--checkbox-size);
    min-width: var(--checkbox-size);
    height: var(--checkbox-size);
    border: calc(var(--checkbox-size) / 12) solid
      var(--checkbox-border-color, var(--checkbox-background-color));
    cursor: pointer;
    margin: 0 var(--checkbox-right-margin) 0 0;

    &:checked {
      background-color: var(--checkbox-background-color);
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: calc(var(--checkbox-size) / 4);
        top: 0;
        width: calc(var(--checkbox-size) / 3.2);
        height: calc(var(--checkbox-size) / 1.6);
        border: solid var(--checkbox-check-color);
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &:indeterminate {
      border: calc(var(--checkbox-size) / 4) solid
        var(--checkbox-background-color);
    }
  }

  .checkbox-label {
    overflow-wrap: anywhere;
  }
}
