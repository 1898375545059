@import '../../styles/variables';

.radio-wrapper label {
  display: flex;
  align-items: center;
  width: 100%;

  input[type='radio'] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    outline: none;

    /* creating a custom design */
    width: var(--radio-size);
    height: var(--radio-size);
    border: calc(var(--radio-size) / 12) solid var(--radio-check-color);
    border-radius: 50%;
    cursor: pointer;
    margin: 0 var(--radio-right-margin) 0 0;
    position: relative;

    &:checked {
      background-color: var(--radio-background-color);

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(var(--radio-size) / 2);
        height: calc(var(--radio-size) / 2);
        background-color: var(--radio-check-color);
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
