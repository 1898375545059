@import '../../styles/variables';

.submit-button {
  text-align: var(--submit-button-align, center);
}

.subscription-options {
  display: flex;
  flex-direction: column;
  align-items: var(--subscription-options-align);

  .subscription-options-title {
    margin-top: 0;
    margin-bottom: var(--subscription-options-gap, revert);
    font-size: var(
      --subscription-options-title-font-size,
      var(--primary-font-size)
    );
    font-weight: var(--subscription-options-title-font-weight, initial);
    color: var(--subscription-options-title-color, var(--primary-text-color));
  }

  .subscription-options-group-name {
    margin-top: var(--subscription-options-group-margin-top, 0);
  }

  .subscription-options-list {
    display: grid;
    column-gap: var(--checkbox-column-gap, 0);
    row-gap: var(--checkbox-gap);
    align-items: center;
    list-style-type: none;
    padding: var(--subscription-options-list-padding, initial);
    margin: 0;
    grid-template-columns: repeat(
      auto-fill,
      minmax(
        calc(
          calc(
              var(--max-content-width) - var(--content-padding-right) -
                var(--content-padding-left) - var(--content-item-padding-right) -
                var(--content-item-padding-left)
            ) / var(--subscription-options-max-column-number, 3)
        ),
        1fr
      )
    );

    &.group-options {
      grid-template-columns: repeat(
        auto-fill,
        minmax(
          calc(
            calc(
                var(--max-content-width) - var(--content-padding-right) -
                  var(--content-padding-left) -
                  var(--content-item-padding-right) -
                  var(--content-item-padding-left)
              ) /
              var(
                --subscription-group-options-max-column-number,
                var(--subscription-options-max-column-number, 3)
              )
          ),
          1fr
        )
      );
    }

    li > ul {
      margin-top: var(--checkbox-gap);
    }
  }
}

@media screen and (max-width: $mobileMaxWidth) {
  .subscription-options .subscription-options-list {
    grid-template-columns: 1fr;
    grid-row-gap: var(--checkbox-gap-mobile, var(--checkbox-gap));

    &.group-options {
      grid-template-columns: 1fr;
    }

    li > ul {
      margin-top: var(--checkbox-gap-mobile, var(--checkbox-gap));
    }
  }
}
