@import '../../styles/variables';
.intro {
  &-header {
    font-family: var(--intro-header-font-family, var(--primary-font-family));
    font-weight: var(--intro-header-font-weight, revert);
    font-size: var(--intro-header-font-size, initial);
    text-align: var(--intro-header-align);
    color: var(--intro-header-color, var(--primary-text-color));
  }

  &-content {
    text-align: var(--intro-text-align);
  }

  @media screen and (max-width: $mobileMaxWidth) {
    &-header {
      font-size: var(
        --intro-header-font-size-mobile,
        var(--intro-header-font-size, initial)
      );
      text-align: var(--intro-header-align-mobile);
    }

    &-content {
      text-align: var(--intro-text-align-mobile);
    }
  }
}
