@import '../../styles/variables';

.update-all {
  display: flex;
  flex-direction: column;
  align-items: var(--update-all-align);
  padding-left: var(--update-all-padding-left, 0);

  .update-all-title {
    margin-bottom: var(--update-all-gap, 0);
  }
}
