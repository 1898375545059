.language-selector {
  position: absolute;
  right: var(--header-padding-right);
  top: calc(
    (
        var(--header-padding-top) + var(--header-padding-bottom) +
          var(--logo-height) - 33px
      ) / 2
  );
  display: flex;
  align-items: center;

  .language-icon {
    margin-right: var(--language-icon-margin-right, 8px);
    min-width: 20px;
  }
}
