body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --default-focus-color-light: #fff;
  --default-focus-color-dark: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus-visible {
  outline: none;
  /* stylelint-disable */
  box-shadow: 0 0 0 0.2em var(--default-focus-color-light),
    0 0 0 0.4em var(--default-focus-color-dark);
  /* stylelint-enable */
}
