@import '../../styles/variables';

.home-page {
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: var(--primary-font-family);
  font-size: var(--primary-font-size);
  background: var(--background-color);
  color: var(--primary-text-color);
  line-height: var(--line-height, 1.2);
  min-height: 100vh;

  .home-layout {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--content-margin-right);
    padding: var(--layout-padding-top) var(--layout-padding-right)
      var(--layout-padding-bottom) var(--layout-padding-left);
    margin: 0 auto auto;
    width: 100%;
  }

  .home-content {
    background-color: var(--content-background);
    max-width: var(--max-content-width);
    width: 100%;
    justify-self: end;
    padding: var(--content-padding-top) var(--content-padding-right)
      var(--content-padding-bottom) var(--content-padding-left);

    .home-content-item {
      padding: var(--content-item-padding-top) var(--content-item-padding-right)
        var(--content-item-padding-bottom) var(--content-item-padding-left);

      border: var(--content-border-color, transparent) solid;
      border-width: 0 0 var(--content-border-width);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .banner-top {
    display: none;
  }

  .banner-right {
    justify-self: start;
    display: block;
  }
}

@media screen and (max-width: $mobileMaxWidth) {
  .home-page {
    grid-template-rows: auto auto 1fr auto;

    .home-layout {
      grid-template-columns: 1fr;
      padding: var(--layout-padding-top-mobile)
        var(--layout-padding-right-mobile) var(--layout-padding-bottom-mobile)
        var(--layout-padding-left-mobile);
      width: auto;

      .home-content {
        max-width: 100%;
        padding: var(--content-padding-top-mobile)
          var(--content-padding-right-mobile)
          var(--content-padding-bottom-mobile)
          var(--content-padding-left-mobile);

        .home-content-item {
          padding: var(--content-item-padding-top-mobile)
            var(--content-item-padding-right-mobile)
            var(--content-item-padding-bottom-mobile)
            var(--content-item-padding-left-mobile);
          border: var(--content-border-color, transparent) solid;
          border-width: 0 0 var(--content-border-width);

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .banner-top {
      display: block;
    }

    .banner-right {
      display: none;
    }
  }
}
