.dropdown {
  position: relative;
  display: flex;
  align-items: center;

  .dropdown-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .dropdown-trigger {
    font-family: var(--primary-font-family);
    color: var(--dropdown-trigger-text-color, var(--primary-text-color));
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: var(--dropdown-trigger-padding-top)
      var(--dropdown-trigger-padding-right)
      var(--dropdown-trigger-padding-bottom)
      var(--dropdown-trigger-padding-left);
    background-color: var(--dropdown-trigger-background);
    border: 1px solid var(--dropdown-border-color);
    width: var(--dropdown-trigger-width, auto);
    cursor: pointer;
  }

  .dropdown-trigger-icon {
    height: 0;
    width: 0;
    margin-left: var(--dropdown-trigger-icon-margin-left, 16px);
    border: none;
    border-top: 5px solid
      var(--dropdown-trigger-text-color, var(--primary-text-color));
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(0, 0, 0, 0);

    &__opened {
      border-bottom: 5px solid
        var(--dropdown-trigger-text-color, var(--primary-text-color));
      border-top: none;
    }
  }

  .dropdown-menu-wrapper {
    position: absolute;
    width: max-content;
    max-height: 300px;
    overflow-y: auto;
    padding: 8px 0;
    border-radius: 4px;
    border: 1px solid var(--dropdown-border-color);
    background-color: var(--dropdown-background);
    color: var(--primary-text-color);
    z-index: 10;
    top: 100%;
    right: 0;
    margin-top: 8px;
  }

  .dropdown-menu {
    padding-left: 0;
    margin: 0;
  }

  .dropdown-menu-item {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: var(--dropdown-item-padding-top) var(--dropdown-item-padding-right)
      var(--dropdown-item-padding-bottom) var(--dropdown-item-padding-left);
    height: var(--dropdown-item-height);

    &__selected {
      background-color: var(--dropdown-background-selected);

      &:after {
        content: '\2713';
        display: inline-block;
        color: var(--primary-text-color);
        padding: 0 6px 0 0;
      }
    }

    &:hover {
      background-color: var(--dropdown-background-hover);
    }
  }
}
