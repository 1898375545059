.result-page {
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: var(--primary-font-family);
  font-size: var(--primary-font-size);
  background: var(--background-color);
  color: var(--primary-text-color);
  min-height: 100vh;

  .content {
    background-color: var(--content-background);
    margin: var(--result-content-margin) auto auto;
    text-align: var(--result-content-align) center;
    padding: var(--result-content-padding) 20px;

    .back-button {
      text-align: center;
    }
  }
}
