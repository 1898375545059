@import '../../styles/variables';

.footer {
  background-color: var(--footer-background-color);
  padding: var(--footer-padding-top) var(--footer-padding-right)
    var(--footer-padding-bottom) var(--footer-padding-left);
  text-align: var(--footer-align);
  color: var(--footer-text-color);

  * {
    color: inherit;
  }

  @media screen and (max-width: $mobileMaxWidth) {
    padding: var(--footer-padding-top-mobile, var(--footer-padding-top))
      var(--footer-padding-right-mobile, var(--footer-padding-right))
      var(--footer-padding-bottom-mobile, var(--footer-padding-bottom))
      var(--footer-padding-left-mobile, var(--footer-padding-left));
  }
}
