@import '../../styles/variables';

.banner-image {
  max-width: var(--banner-width);
  max-height: var(--banner-height);
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: $mobileMaxWidth) {
  .banner-image {
    max-width: none;
    max-height: none;
    width: var(--banner-width-mobile);
    height: var(--banner-height-mobile);
  }
}
