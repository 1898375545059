@import '../../styles/variables';

.header {
  background-color: var(--header-background-color);
  padding: var(--header-padding-top) var(--header-padding-right)
    var(--header-padding-bottom) var(--header-padding-left);
  text-align: var(--header-align);
  border: var(--header-border-color, initial) solid;
  border-width: 0 0 var(--header-border-width, 0);

  .header-logo {
    display: block;
    line-height: 0;
  }

  @media screen and (max-width: $mobileMaxWidth) {
    padding: var(--header-padding-top-mobile, var(--header-padding-top))
      var(--header-padding-right-mobile, var(--header-padding-right))
      var(--header-padding-bottom-mobile, var(--header-padding-bottom))
      var(--header-padding-left-mobile, var(--header-padding-left));
    text-align: var(--header-align-mobile, var(--header-align));
  }
}
