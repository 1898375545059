@import '../../styles/variables';

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-family: var(--button-font-family, var(--primary-font-family));
  font-size: var(--button-font-size, initial);
  font-weight: var(--button-font-weight, initial);
  text-transform: var(--button-text-transform, none);
  text-align: center;

  background-color: var(--button-background-color);
  color: var(--button-text-color, var(--primary-text-color));
  width: var(--button-width);
  padding: var(--button-padding);

  border: var(--button-border-color, initial) solid;
  border-width: var(--button-border-width, 0);

  outline: 0;
  cursor: pointer;

  &-content {
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &[type='submit'] {
    background-color: var(
      --submit-button-background-color,
      var(--button-background-color)
    );
    color: var(--submit-button-text-color, var(--button-text-color));
    font-size: var(--submit-button-font-size, var(--button-font-size, initial));
    font-weight: var(
      --submit-button-font-weight,
      var(--button-font-weight, initial)
    );
    text-transform: var(
      --submit-button-text-transform,
      var(--button-text-transform, none)
    );
    letter-spacing: var(--submit-button-letter-spacing, initial);
    width: var(--submit-button-width, var(--button-width));
    padding: var(--submit-button-padding, var(--button-padding));

    border: var(
        --submit-button-border-color,
        var(--button-border-color, initial)
      )
      solid;
    border-width: var(
      --submit-button-border-width,
      var(--button-border-width, 0)
    );

    &:hover {
      color: var(
        --submit-button-color-hover,
        var(--submit-button-text-color, var(--button-text-color))
      );
      background-color: var(
        --submit-button-background-color-hover,
        var(--submit-button-background-color, var(--button-background-color))
      );
    }

    &:disabled {
      &:hover {
        color: var(--submit-button-text-color, var(--button-text-color));
        background-color: var(
          --submit-button-background-color,
          var(--button-background-color)
        );
      }
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
